/* eslint-disable @typescript-eslint/indent */
import { useContext } from 'react';
import { FAIR_PRICING_SERVICE_FEE_RATE, FAIR_PRICING_VARIANT } from '@constants/fairPricing';
import { CheckoutInfo } from '@customTypes/checkout-info';
import { HotSpotMin } from '@customTypes/hot-spot';
import HotelApi from '@customTypes/hotel-api';
import HotelDetails from '@customTypes/hotel-details';
import Product from '@customTypes/product';
import { startOfDay } from 'date-fns';
import { store } from '@context/store';
import { convertCurrency, showIsoCode } from '@helpers/currencyConverter';
import {
  calculateAddonsPrice,
  calculateFairPricingFee,
  calculateHotSpotDiscount,
  isProductUnAvailable,
} from '@helpers/product';
import moment from 'moment-timezone';
import daysNames from '@constants/dayNameList';
import { CartItemDetails } from '@customTypes/cart-items';
import useSessionStorage from './useSessionStorage';

export type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export default function useHotSpot() {
  const convertToPSTAndFormat = (date: string | Date, formatString: string = 'MM/DD/YY') => {
    const parsedDate = moment.utc(date);
    const pstDate = parsedDate.tz('America/Los_Angeles');
    return pstDate.format(formatString);
  };

  const calculateTimeLeft = (targetDate: Date): TimeLeft => {
    const endDate = moment.utc(targetDate);
    const nowUTC = moment.utc();
    const differenceInMs = endDate.diff(nowUTC);

    if (differenceInMs <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const msInDay = 1000 * 60 * 60 * 24;
    const msInHour = 1000 * 60 * 60;
    const msInMinute = 1000 * 60;

    const days = Math.floor(differenceInMs / msInDay);
    const hours = Math.floor((differenceInMs % msInDay) / msInHour);
    const minutes = Math.floor((differenceInMs % msInHour) / msInMinute);
    const seconds = Math.floor((differenceInMs % msInMinute) / 1000);

    return { days, hours, minutes, seconds };
  };

  function isHotelDetails(hotel: HotelDetails | HotelApi): hotel is HotelDetails {
    return (hotel as HotelDetails).hot_spot !== undefined;
  }

  function isHotelApi(hotel: HotelDetails | HotelApi): hotel is HotelApi {
    return (hotel as HotelApi).hotSpot !== undefined;
  }

  const isDateWithinVisitWindow = (date: Date, hotel: HotelDetails | HotelApi) => {
    if ((isHotelDetails(hotel) && !hotel.hot_spot) || (isHotelApi(hotel) && !hotel.hotSpot)) {
      return false;
    }

    const startDate = new Date(
      isHotelDetails(hotel) && hotel.hot_spot
        ? hotel.hot_spot.start_visit_date
        : (hotel as HotelApi).hotSpot?.start_date || '',
    );
    const endDate = new Date(
      isHotelDetails(hotel) && hotel.hot_spot
        ? hotel.hot_spot.end_visit_date
        : (hotel as HotelApi).hotSpot?.end_date || '',
    );

    return startOfDay(date) >= startOfDay(startDate) && startOfDay(date) <= startOfDay(endDate);
  };

  const getDiscountText = (hotSpot: HotSpotMin) => {
    if (hotSpot.discount_type === 'percentage') {
      return `${hotSpot.discount}%`;
    }

    return `${hotSpot.discount}$`;
  };

  const applyHotSpotDiscountToTotal = (
    price: number,
    hotSpot: HotSpotMin,
    showFairPricing: boolean,
  ): number => {
    const discountedPrice =
      hotSpot.discount_type === 'cash'
        ? price - hotSpot.discount
        : price * (1 - hotSpot.discount / 100);

    if (showFairPricing) {
      if (FAIR_PRICING_VARIANT === 'variant1') {
        const fairPricingPrice = price * FAIR_PRICING_SERVICE_FEE_RATE;

        return Math.round(discountedPrice + fairPricingPrice);
      }
    }

    return Math.round(Math.max(discountedPrice, 0));
  };

  const getBadgeText = (targetDate: Date, hotSpot: HotSpotMin): string => {
    const globalState = useContext(store);
    const { state } = globalState;
    const { selectedCurrency } = state;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { iso_code } = selectedCurrency;
    const { getItem } = useSessionStorage();
    const isShowIsoCode = showIsoCode(state, getItem);
    const finalValue = convertCurrency(hotSpot.discount, state);

    const endDate = moment.utc(targetDate);
    const now = moment.utc();
    const differenceInDs = endDate.diff(now, 'days');

    switch (differenceInDs) {
      case 0:
        return 'Ends Today';
      case 1:
        return 'Ends in 1 Day';
      case 2:
        return 'Ends in 2 Days';
      case 3:
        return 'Ends in 3 Days';
      default:
        if (differenceInDs > 3) {
          return `Save ${
            hotSpot.discount_type === 'cash'
              ? `${finalValue}${isShowIsoCode ? iso_code : ''}`
              : `${hotSpot.discount}%`
          }`;
        }
        return '';
    }
  };

  const getSubtotal = (checkoutDetails: CheckoutInfo, showFairPricing: boolean): number => {
    const subTotalPrice = checkoutDetails.extracted_items.reduce((acc, item) => {
      const totalProductPrice = item.total;

      const baseAddOnsPrice = calculateAddonsPrice(item.addon_items);
      const addonsPrice =
        baseAddOnsPrice > 0 && showFairPricing
          ? +baseAddOnsPrice + calculateFairPricingFee(baseAddOnsPrice)
          : baseAddOnsPrice;

      const fairPricingFee = showFairPricing ? calculateFairPricingFee(totalProductPrice) : 0;

      const hotSpotDiscount = checkoutDetails.hot_spot
        ? calculateHotSpotDiscount({
            totalProductPrice,
            applyHotSpot: item.is_hot_spot || false,
            hotSpot: checkoutDetails.hot_spot,
          })
        : 0;

      const total = Math.round(totalProductPrice + fairPricingFee - hotSpotDiscount + addonsPrice);

      return acc + total;
    }, 0);

    return subTotalPrice;
  };

  const getTheTimeWithTheLowestPrice = (
    times: Product[],
    hotelDetails: HotelDetails,
    cart: CartItemDetails | undefined,
  ) => {
    let lowestPriceTime = times[0];
    let lowestPrice = +times[0].adult_price;

    times.forEach((time) => {
      if (isProductUnAvailable(time, cart)) return;

      const timePriceAfterDiscounts =
        time.is_hot_spot && hotelDetails.hot_spot
          ? applyHotSpotDiscountToTotal(+time.adult_price, hotelDetails.hot_spot, false)
          : +time.adult_price;

      if (
        timePriceAfterDiscounts < lowestPrice ||
        (timePriceAfterDiscounts === lowestPrice && time.is_adult_strikethrough_pricing) ||
        (timePriceAfterDiscounts === lowestPrice && time.is_hot_spot) ||
        (lowestPrice === 0 && timePriceAfterDiscounts > 0)
      ) {
        lowestPrice = +time.adult_price;
        lowestPriceTime = time;
      }
    });

    return lowestPriceTime;
  };

  const formatDays = (days: number[]): string => {
    // If all 7 days are included or no days are provided, return an empty string
    if (days.length === 7 || days.length === 0) return '';

    // Sort days for easy range detection
    const sortedDays = [...days].sort((a, b) => a - b);

    const ranges: string[] = [];
    let startDay = sortedDays[0];
    let endDay = sortedDays[0];

    for (let i = 1; i < sortedDays.length; i += 1) {
      if (sortedDays[i] === endDay + 1) {
        // Continue the range
        endDay = sortedDays[i];
      } else {
        // Add the current range to the result
        if (startDay === endDay) {
          ranges.push(daysNames[startDay]);
        } else if (endDay === startDay + 1) {
          ranges.push(`${daysNames[startDay]}, ${daysNames[endDay]}`);
        } else {
          ranges.push(`${daysNames[startDay]} through ${daysNames[endDay]}`);
        }
        // Start a new range
        startDay = sortedDays[i];
        endDay = sortedDays[i];
      }
    }

    // Handle the last range
    if (startDay === endDay) {
      ranges.push(daysNames[startDay]);
    } else if (endDay === startDay + 1) {
      ranges.push(`${daysNames[startDay]}, ${daysNames[endDay]}`);
    } else {
      ranges.push(`${daysNames[startDay]} through ${daysNames[endDay]}`);
    }

    // Join the ranges with appropriate separators
    return `Applicable on ${ranges.join(', ').replace(/,([^,]*)$/, ' & $1')}. `;
  };

  return {
    applyHotSpotDiscountToTotal,
    calculateTimeLeft,
    convertToPSTAndFormat,
    getDiscountText,
    getSubtotal,
    getTheTimeWithTheLowestPrice,
    isDateWithinVisitWindow,
    getBadgeText,
    formatDays,
  };
}
